import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import styles from "./PeerEvaluations.module.scss";
import { GroupedResult, Question, SheetRow } from "./types";
import { Questions } from "./Questions";
import { detectQuestionType } from "./helpers";
import { Evaluatees } from "./Evaluatees";
import { EvaluationParameters, EvaluationParametersData } from "./EvaluationParameters";

const defaultColumns = [
  "ID",
  "Start time",
  "Completion time",
  "Email",
  "Name",
  "Heure de début",
  "Heure de fin",
  "Adresse de messagerie",
  "Nom",
  "Heure de la dernière modification",
];

export function PeerEvaluations(): JSX.Element {
  const [rows, setRows] = useState<SheetRow[]>([]);
  const [results, setResults] = useState<GroupedResult>({});
  const [questions, setQuestions] = useState<Question[]>([]);
  const [parameters, setParameters] = useState<EvaluationParametersData>({
    evaluateeQuestionName: "Qui évalues-tu? Inscrire le nom complet de la personne : Prénom et nom",

    // This is unused, but most code requires it.
    evaluatorQuestionName:
      "Si tu souhaites le faire, tu peux laisser ton nom pour plus de discussion personnalisé (sinon le formulaire demeure anonyme et c'est aussi parfait ainsi)",
    evaluationName: "Évaluation Septembre-2024",
  });

  useEffect(() => {
    const results = groupData(rows, parameters.evaluateeQuestionName);
    setResults(results);
    const questions = findQuestions(rows);
    setQuestions(questions);
  }, [rows, parameters.evaluateeQuestionName]);

  const onFilesUploaded = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length !== 1) {
      console.error("No files uploaded or more than one");
      return;
    }

    const f = files[0];

    /* f is a File */
    const data = await f.arrayBuffer();
    /* data is an ArrayBuffer */
    const workbook = read(data);
    const sheets = utils.sheet_to_json(workbook.Sheets["Sheet1"], {
      defval: "",
    });
    setRows(sheets as SheetRow[]);
  };

  return (
    <section className={styles["peer-evaluations"]}>
      <EvaluationParameters
        onFilesUploaded={onFilesUploaded}
        parameters={parameters}
        onParametersChanged={setParameters}
      />

      <Questions questions={questions} onQuestionsChanged={setQuestions} />

      <Evaluatees
        questions={{
          questions,
          parameters,
        }}
        evaluatees={results}
      />
    </section>
  );
}

function groupData(rows: SheetRow[], groupByColumn: string): GroupedResult {
  const results: GroupedResult = {};
  for (const row of rows) {
    const columnValue = row[groupByColumn] as string;
    const normalizedKey = normalizeString(columnValue);
    if (!results[normalizedKey]) {
      results[normalizedKey] = [];
    }

    results[normalizedKey].push(row);
  }

  return results;
}

/**
 * Normalize a search query.

 * NFD Unicode normal form decomposes combined graphemes into the combination of simple ones.
 * ex: The é of Québec ends up expressed as e + ´
 * Using the Unicode property escapes to globally get rid of the diacritics

 * {@link https://stackoverflow.com/a/37511463 Source: Stack Overflow}
 */
function normalizeString(str: string = ""): string {
  return str
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/[\W_]/g, " ")
    .replace(/\s\s+/g, " ")
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    .trim();
}

function findQuestions(rows: SheetRow[]): Question[] {
  const questions: Question[] = [];
  const firstRow = rows[0];
  if (!firstRow) {
    return questions;
  }

  for (const key of Object.keys(firstRow)) {
    if (defaultColumns.includes(key)) {
      continue;
    }

    // Since some questions are nullable, we have to get the value from other rows!
    let value = firstRow[key];
    let idx = 1;
    while (!value) {
      value = rows[idx++][key];
    }

    questions.push({
      name: key,
      type: detectQuestionType(key, value),
    });
  }

  return questions;
}
