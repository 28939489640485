import { useEffect } from "react";

import styles from "./EvaluationParameters.module.scss";
export interface EvaluationParametersData {
  evaluateeQuestionName: string;
  evaluatorQuestionName: string;
  evaluationName: string;
}

interface EvaluationParametersProps {
  parameters: EvaluationParametersData;
  onFilesUploaded: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onParametersChanged: (parameters: EvaluationParametersData) => void;
}

const parametersKey = "eval360noscope:parameters";

export function EvaluationParameters({
  parameters,
  onFilesUploaded,
  onParametersChanged,
}: EvaluationParametersProps): JSX.Element {
  useEffect(() => {
    const value = localStorage.getItem(parametersKey);
    if (value) {
      onParametersChanged(JSON.parse(value));
    }
  });

  const changeParameters = (params: EvaluationParametersData) => {
    onParametersChanged(params);
    localStorage.setItem(parametersKey, JSON.stringify(params));
  };

  const onGroupByChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeParameters({
      ...parameters,
      evaluateeQuestionName: e.target.value,
    });
  };

  // Unused for now.
  // const onEvaluatorColumnNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   changeParameters({
  //     ...parameters,
  //     evaluatorQuestionName: e.target.value,
  //   });
  // };
  const onEvaluationNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeParameters({
      ...parameters,
      evaluationName: e.target.value,
    });
  };

  return (
    <form className={styles.parameters}>
      <div className="input-group">
        <label>
          Nom de l'évaluation
          <input
            type="text"
            name="evaluationName"
            value={parameters.evaluationName}
            onChange={onEvaluationNameChanged}
          />
        </label>
      </div>
      <div className="input-group">
        <label>
          Nom de la colonne de la personne évaluée
          <input
            type="text"
            name="groupByColumn"
            value={parameters.evaluateeQuestionName}
            onChange={onGroupByChanged}
          />
        </label>
      </div>
      {/* Unused for now.
       <div className="input-group">
        <label>
          Nom de la colonne de la personne qui évalue (optionnel)
          <input
            type="text"
            name="evaluatorColumnName"
            value={parameters.evaluatorQuestionName}
            onChange={onEvaluatorColumnNameChanged}
          />
        </label>
      </div> */}
      <div className="input-group">
        <label>
          Téléverser le fichier Excel exportée de Microsoft Forms
          <input type="file" onChange={onFilesUploaded} />
        </label>
      </div>
    </form>
  );
}
